import { OutboundLink } from "gatsby-plugin-google-gtag";
import React from "react";
import Layout from "../components/layout";
import {
  ShoppingCartStateConsumer,
  ShoppingCartDispatchConsumer,
} from "../contexts/shoppingCart";
import { actions } from "../contexts/shoppingCart/actions";

const ShoppingCart = () => {
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <p className="title is-3">Shopping Cart</p>
          <ShoppingCartStateConsumer>
            {({ client, checkout }) => {
              const lineItems =
                checkout && checkout.lineItems ? (
                  checkout.lineItems.length === 0 ? (
                    <article className="message">
                      <div className="message-body">Your cart is empty</div>
                    </article>
                  ) : (
                    checkout.lineItems.map((l) => (
                      <div className="card" key={`card-lineItem-${l.id}`}>
                        <div className="card-content">
                          <div className="media">
                            <div className="media-left">
                              {l?.variant?.image?.src ? (
                                <figure className="image">
                                  <img
                                    src={l?.variant?.image?.src}
                                    alt={l.title}
                                    style={{ width: "128px" }}
                                  />
                                </figure>
                              ) : (
                                <span></span>
                              )}
                            </div>
                            <div className="media-content">
                              <div className="columns">
                                <div className="column is-half">
                                  <p className="title is-4">{l.title}</p>
                                  <p className="subtitle is-5">
                                    {l.variant.title !== "Default Title"
                                      ? l.variant.title
                                      : ""}
                                  </p>
                                </div>
                                <div className="column is-half has-text-right">
                                  <p className="title">
                                    &#x20B9;
                                    {Math.floor(
                                      parseFloat(l.variant.price)
                                    ).toLocaleString()}
                                  </p>
                                </div>
                              </div>
                              <ShoppingCartDispatchConsumer>
                                {(dispatch) => (
                                  <button
                                    className="button is-primary"
                                    onClick={() => {
                                      actions.removeLineItem(
                                        dispatch,
                                        client,
                                        checkout.id,
                                        l.id
                                      );
                                    }}
                                  >
                                    Remove from cart
                                  </button>
                                )}
                              </ShoppingCartDispatchConsumer>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  )
                ) : null;
              return (
                <div>
                  {lineItems}
                  {lineItems !== null ? (
                    <div className="card">
                      <div className="card-content">
                        <div className="media">
                          <div className="media-content">
                            <hr />
                            <div className="columns">
                              <div className="column is-half">
                                <p className="title is-4">Total</p>
                              </div>
                              <div className="column is-half has-text-right">
                                <p className="title">
                                  {" "}
                                  &#x20B9;
                                  {Math.floor(
                                    parseFloat(checkout.paymentDue)
                                  ).toLocaleString()}
                                </p>

                                <OutboundLink
                                  className="button is-primary"
                                  href={checkout.webUrl}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  Checkout
                                </OutboundLink>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
              );
            }}
          </ShoppingCartStateConsumer>
        </div>
      </section>
    </Layout>
  );
};

export default ShoppingCart;
